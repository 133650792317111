
import { usePinia } from '#imports'
import AuthMixin from '~/mixins/AuthMixin'
import { useFavouritesStore } from '~/stores/useFavouritesStore'
import { Component, Mixins } from '~/utility/pu-class-decorator'

@Component({
  setup() {
    const favStore = useFavouritesStore(usePinia())
    return { favStore }
  },
})
export default class AppHeaderFavouritesCount extends Mixins(AuthMixin) {
  favStore: ReturnType<typeof useFavouritesStore>
  get favCount() {
    return this.favStore.totalCount
  }
}
